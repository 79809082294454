<template>
	<v-layout class="d-flex setting-layout">
		<div class="setting-sidebar">
			<v-tabs
				vertical
				v-model="settingTab"
				color="blue darken-4"
				class="w-100 no-style-tabs"
				active-class="blue lighten-5"
			>
				<template v-for="(tab, index) in tabs">
					<template v-if="tab.permitTo.includes(userType) && getPermission(tab.customPermission)">
						<v-tab :href="'#' + tab.key" :key="tab.key + '_' + index">
							{{ tab.title }}
						</v-tab>
					</template>
				</template>
			</v-tabs>
		</div>
		<div class="setting-content">
			<v-tabs-items v-model="settingTab">
				<template v-for="(tab, index) in tabs">
					<template v-if="tab.permitTo.includes(userType)">
						<v-tab-item :key="tab.key + '_' + index" :value="tab.key">
							<component :is="tab.component"></component>
						</v-tab-item>
					</template>
				</template>
			</v-tabs-items>
		</div>
	</v-layout>
</template>
<script>
import MainMixin from "@/core/mixins/main.mixin.js";
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { SET_SEARCH_BAR } from "@/core/services/store/listing.module";
import { EventBus } from "@/core/event-bus/event.bus";

export default {
	name: "AllSettings",
	mixins: [MainMixin],
	components: dynamicComponents(),
	data() {
		return {
			login_user: null,
			isAccess: false,
			userRole: 0,
			userType: null,
			tabs: allTabs,
		};
	},
	created() {
		const _this = this;
		// if (_this.$route.query && Object.hasOwn(_this.$route.query, 'tab')) {
		_this.settingTab = _this.$route.query.tab || "personal_setting";
		// }
	},
	watch: {
		settingTab: {
			deep: true,
			handler(val) {
				if (val == "service_provider" || val == "server" || val == "holiday_calendar") {
					this.$store.dispatch(SET_SEARCH_BAR, true);
				} else {
					this.$store.dispatch(SET_SEARCH_BAR, false);
				}

				EventBus.$emit("refresh:fields", true);
			},
		},
	},
	computed: {
		...mapGetters(["currentUser"]),
		settingTab: {
			set(val) {
				let query = { ...this.$route.query };
				query.tab = val;
				if (val != this.settingTab) {
					this.$router.push({
						path: "setting",
						query: { tab: query.tab },
					});
					this.setBreadcrumb();
				}
			},
			get() {
				return this.$route.query.tab || "personal_setting";
			},
		},
	},
	methods: {
		// currentUserData() {
		// 	const user = getCurrentUser();

		// 	if (user) {
		// 		this.login_user = user;
		// 		if (!objectPath.get(user, "users_type")) {
		// 			this.login_user = { ...user, users_type: "super_admin" };
		// 		}
		// 		//console.log(this.login_user);
		// 	}
		// 	//this.leaveCreate.staff = user.id;
		// 	//this.getCategoryOptions(user.id);
		// },
		setBreadcrumb() {
			this.$store.dispatch(SET_BREADCRUMB, [
				{ text: "Setting", disabled: true },
				{
					text: this.mod_string(this.settingTab),
					disabled: true,
					href: `/setting?tab=${this.settingTab}`,
				},
			]);
		},
	},
	mounted() {
		this.setBreadcrumb();
		const { role, users_type } = this.AllowAnyOforGetRoleNType();
		this.userRole = role;
		this.userType = users_type;
		let roleType = this.checkAdminOrManager();
		if (
			roleType == "admin" ||
			roleType == "manager" ||
			roleType == "super_admin" ||
			roleType == "master"
		) {
			this.isAccess = true;
		}
	},
};

const components = {
	PersonalSettings: () => import("@/view/module/setting/personal/PersonalSettings"),
	RolePermission: () => import("@/view/module/setting/RolePermissionPages/RolePermission"),
	CompanySetting: () => import("@/view/module/setting/company/CompanySetting"),
	ServiceProvider: () => import("@/view/module/setting/ServiceProvider"),
	Server: () => import("@/view/module/setting/Server"),
	LeadSetting: () => import("@/view/module/setting/LeadSetting"),
	CustomerSetting: () => import("@/view/module/setting/CustomerSetting"),
	MeetingSetting: () => import("@/view/module/setting/MeetingSetting"),
	LeaveSetting: () => import("@/view/module/setting/LeaveSetting"),
	LeaveCategory: () => import("@/view/module/setting/LeaveCategory"),
	EmailNotifications: () => import("@/view/module/setting/EmailNotifications"),
	HolidaySetting: () => import("@/view/module/setting/HolidaySetting"),
	HolidayCalendar: () => import("@/view/module/holiday/Holiday"),
	TaskSetting: () => import("@/view/module/setting/TaskSetting"),
	ClaimSetting: () => import("@/view/module/setting/ClaimSetting"),
	ClaimCategory: () => import("@/view/module/setting/ClaimCategory"),
	BacklinkActivity: () => import("@/view/module/setting/BacklinkActivity"),
	BacklinkCategory: () => import("@/view/module/setting/BacklinkCategory"),
	BacklinkLibrary: () => import("@/view/module/setting/BacklinkLibrary"),
	ContractSetting: () => import("@/view/module/setting/ContractSetting"),
	ProjectSetting: () => import("@/view/module/setting/ProjectSetting"),
	InvoiceSetting: () => import("@/view/module/setting/InvoiceSetting"),
	StaffSetting: () => import("@/view/module/setting/StaffSetting"),
	Integration: () => import("@/view/module/setting/Integration"),
	EmailTemplate: () => import("@/view/module/setting/email/EmailTemplate"),
	WhatsApp: () => import("@/view/module/setting/WhatsApp"),
	GoogleCredentials: () => import("@/view/module/setting/GoogleCredentials/GoogleCredentials"),
	IPAddress: () => import("@/view/module/setting/IPAddress"),
	CmsTypeSetting: () => import("@/view/module/setting/CmsTypeSetting"),
};

/* helps avoiding unnecessary scrolls while development */
function dynamicComponents(type = null) {
	if (type && (Object.hasOwn(components, type) || type in components)) {
		return components[type];
	} else {
		return components;
	}
}

const allTabs = [
	{
		key: "personal_setting",
		title: "Personal Setting",
		permitTo: ["seo", "sales", "accounts"],
		customPermission: null,
		component: dynamicComponents("PersonalSettings"),
	},
	{
		key: "role_&_permission",
		title: "Role & Permission",
		permitTo: ["seo", "sales", "accounts"],
		customPermission: null,
		component: dynamicComponents("RolePermission"),
	},
	{
		key: "company_details",
		title: "Company Details",
		permitTo: ["seo", "sales", "accounts"],
		customPermission: null,
		component: dynamicComponents("CompanySetting"),
	},
	{
		key: "service_provider",
		title: "Service Provider",
		permitTo: ["seo", "sales"],
		customPermission: null,
		component: dynamicComponents("ServiceProvider"),
	},
	{
		key: "server",
		title: "Server",
		permitTo: ["sales"],
		customPermission: null,
		component: dynamicComponents("Server"),
	},
	{
		key: "lead",
		title: "Lead",
		permitTo: ["sales", "accounts"],
		customPermission: "lead:view",
		component: dynamicComponents("LeadSetting"),
	},
	{
		key: "customer",
		title: "Customer",
		permitTo: ["sales"],
		customPermission: "customer:view",
		component: dynamicComponents("CustomerSetting"),
	},
	{
		key: "meeting",
		title: "Meeting",
		permitTo: ["sales", "accounts"],
		customPermission: "meeting:view",
		component: dynamicComponents("MeetingSetting"),
	},
	{
		key: "leave",
		title: "Leave",
		permitTo: ["sales", "accounts"],
		customPermission: "leave:view",
		component: dynamicComponents("LeaveSetting"),
	},
	{
		key: "leave_category",
		title: "Leave Category",
		permitTo: ["sales", "accounts"],
		customPermission: "leave:view",
		component: dynamicComponents("LeaveCategory"),
	},
	{
		key: "email_notifications",
		title: "Email Notifications",
		permitTo: ["seo", "accounts", "sales"],
		customPermission: null,
		component: dynamicComponents("EmailNotifications"),
	},
	{
		key: "holiday",
		title: "Holiday",
		permitTo: ["sales", "accounts"],
		customPermission: null,
		component: dynamicComponents("HolidaySetting"),
	},
	{
		key: "holiday_calendar",
		title: "Holiday Calendar",
		permitTo: ["sales", "accounts"],
		customPermission: null,
		component: dynamicComponents("HolidayCalendar"),
	},
	{
		key: "task",
		title: "Task",
		permitTo: ["accounts"],
		customPermission: "task:view",
		component: dynamicComponents("TaskSetting"),
	},
	{
		key: "claim",
		title: "Claim",
		permitTo: ["accounts", "sales"],
		customPermission: "claim:view",
		component: dynamicComponents("ClaimSetting"),
	},
	{
		key: "claim_category",
		title: "Claim Category",
		permitTo: ["accounts", "sales"],
		customPermission: "claim:view",
		component: dynamicComponents("ClaimCategory"),
	},
	{
		key: "backlink_activity",
		title: "Backlink Activity",
		permitTo: ["seo"],
		customPermission: "backlink_activity_settings:view",
		component: dynamicComponents("BacklinkActivity"),
	},
	{
		key: "backlink_category",
		title: "Backlink Category ",
		permitTo: ["seo"],
		customPermission: "backlink_category_settings:view",
		component: dynamicComponents("BacklinkCategory"),
	},
	{
		key: "backlink_library",
		title: "Backlink Library ",
		permitTo: ["seo"],
		customPermission: "backlink_library_settings:view",
		component: dynamicComponents("BacklinkLibrary"),
	},
	{
		key: "contract",
		title: "Contract",
		permitTo: ["sales", "accounts"],
		customPermission: "contract:view",
		component: dynamicComponents("ContractSetting"),
	},
	{
		key: "project",
		title: "Project",
		permitTo: ["sales", "accounts"],
		customPermission: "project:view",
		component: dynamicComponents("ProjectSetting"),
	},
	{
		key: "invoice",
		title: "invoice",
		permitTo: ["accounts"],
		customPermission: "invoice:view",
		component: dynamicComponents("InvoiceSetting"),
	},
	{
		key: "staff",
		title: "Staff",
		permitTo: [],
		customPermission: null,
		component: dynamicComponents("StaffSetting"),
	},
	{
		key: "integration",
		title: "Integration",
		permitTo: ["seo"],
		customPermission: null,
		component: dynamicComponents("Integration"),
	},
	{
		key: "email_template",
		title: "Email Template",
		permitTo: ["seo", "accounts", "sales"],
		customPermission: null,
		component: dynamicComponents("EmailTemplate"),
	},
	{
		key: "whatsapp",
		title: "WhatsApp",
		permitTo: [],
		customPermission: null,
		component: dynamicComponents("WhatsApp"),
	},
	{
		key: "google_credentials",
		title: "API Credentials",
		permitTo: ["seo"],
		customPermission: null,
		component: dynamicComponents("GoogleCredentials"),
	},
	{
		key: "ip_address",
		title: "IP Address",
		permitTo: ["seo", "sales", "accounts"],
		customPermission: null,
		component: dynamicComponents("IPAddress"),
	},
	{
		key: "cms_type",
		title: "CMS Type",
		permitTo: ["seo", "sales", "accounts"],
		customPermission: null,
		component: dynamicComponents("CmsTypeSetting"),
	},
];

/* Remove below code after successfully tested above new code */

/*
				<!-- <v-tab href="#personal_setting">Personal Setting</v-tab> -->
				<!-- v-if="this.currentUser && this.currentUser.role == 1" -->
				<!-- <v-tab href="#role_&_permission" v-if="isAccess">Role & Permission</v-tab>
				<v-tab href="#company_details" v-if="isAccess">Company Details</v-tab>
				<v-tab href="#service_provider" v-if="isAccess">Service Provider</v-tab>
				<v-tab href="#server" v-if="isAccess">Server</v-tab>
				<v-tab href="#lead" v-if="isAccess">Lead</v-tab>
				<v-tab href="#customer" v-if="isAccess">Customer</v-tab>
				<v-tab href="#meeting" v-if="isAccess">Meeting</v-tab>
				<v-tab href="#leave" v-if="isAccess">Leave</v-tab>
				<v-tab href="#leave_category" v-if="isAccess">Leave Category</v-tab>
				<v-tab href="#email_notifications" v-if="isAccess">Email Notifications</v-tab>
				<v-tab href="#holiday" v-if="isAccess">Holiday</v-tab>
				<v-tab href="#holiday_calendar" v-if="isAccess">holiday Calendar</v-tab>
				<v-tab href="#task" v-if="isAccess">Task</v-tab>
				<v-tab href="#claim" v-if="isAccess && userType != 'seo'">Claim</v-tab>
				<v-tab href="#claim_category" v-if="isAccess && userType != 'seo'">Claim Category</v-tab>
				<v-tab
					v-if="(getPermission('backlink_activity_settings:view') || isAccess) && userType == 'seo'"
					href="#backlink_activity"
				>
					Backlink Activity
				</v-tab>
				<v-tab
					v-if="(getPermission('backlink_category_settings:view') || isAccess) && userType == 'seo'"
					href="#backlink_category"
				>
					Backlink Category
				</v-tab>
				<v-tab
					v-if="(getPermission('backlink_library_settings:view') || isAccess) && userType == 'seo'"
					href="#backlink_library"
				>
					Backlink Library
				</v-tab>
				<v-tab href="#contract" v-if="isAccess">Contract</v-tab>
				<v-tab href="#project" v-if="isAccess">Project</v-tab>
				<v-tab href="#invoice" v-if="isAccess">invoice</v-tab>
				<v-tab href="#staff" v-if="isAccess">Staff</v-tab>
				<v-tab href="#integration" v-if="isAccess">Integration</v-tab>
				<v-tab href="#email_template">Email Template</v-tab>
				<v-tab v-if="false" href="#whatsapp">WhatsApp</v-tab>
				<v-tab
					v-if="
						AllowAnyOforGetRoleNType([
							'master',
							'admin',
							'manager',
							'super_admin',
							'team_lead',
							'ateam_lead',
						])
					"
					href="#google_credentials"
					>API Credentials</v-tab
				> --> */
/*

				<!-- <v-tab-item value="personal_setting">
					<PersonalSettings></PersonalSettings>
				</v-tab-item>
				<v-tab-item value="role_&_permission">
					<RolePermission></RolePermission>
				</v-tab-item>
				<v-tab-item value="company_details">
					<CompanySetting></CompanySetting>
				</v-tab-item>
				<v-tab-item value="service_provider">
					<ServiceProvider />
				</v-tab-item>
				<v-tab-item value="server">
					<Server />
				</v-tab-item>
				<v-tab-item value="email_template">
					<EmailTemplate></EmailTemplate>
				</v-tab-item>
				<v-tab-item value="lead">
					<LeadSetting></LeadSetting>
				</v-tab-item>
				<v-tab-item value="customer">
					<CustomerSetting></CustomerSetting>
				</v-tab-item>
				<v-tab-item value="meeting">
					<MeetingSetting></MeetingSetting>
				</v-tab-item>
				<v-tab-item value="leave">
					<LeaveSetting></LeaveSetting>
				</v-tab-item>
				<v-tab-item value="leave_category">
					<LeaveCategory></LeaveCategory>
				</v-tab-item>
				<v-tab-item value="email_notifications">
					<EmailNotifications />
				</v-tab-item>
				<v-tab-item
					v-if="getPermission('backlink_library_settings:view') && userType == 'seo'"
					value="backlink_library"
				>
					<BacklinkLibrary></BacklinkLibrary>
				</v-tab-item>
				<v-tab-item
					v-if="getPermission('backlink_category_settings:view') && userType == 'seo'"
					value="backlink_category"
				>
					<BacklinkCategory></BacklinkCategory>
				</v-tab-item>
				<v-tab-item
					v-if="getPermission('backlink_activity_settings:view') && userType == 'seo'"
					value="backlink_activity"
				>
					<BacklinkActivity></BacklinkActivity>
				</v-tab-item>
				<v-tab-item value="holiday">
					<HolidaySetting></HolidaySetting>
				</v-tab-item>
				<v-tab-item value="holiday_calendar">
					<HolidayCalendar></HolidayCalendar>
				</v-tab-item>
				<v-tab-item value="task">
					<TaskSetting></TaskSetting>
				</v-tab-item>
				<v-tab-item value="claim" v-if="userType != 'seo'">
					<ClaimSetting></ClaimSetting>
				</v-tab-item>
				<v-tab-item value="claim_category" v-if="userType != 'seo'">
					<ClaimCategory></ClaimCategory>
				</v-tab-item>
				<v-tab-item value="contract">
					<ContractSetting></ContractSetting>
				</v-tab-item>
				<v-tab-item value="project">
					<ProjectSetting></ProjectSetting>
				</v-tab-item>
				<v-tab-item value="invoice">
					<InvoiceSetting></InvoiceSetting>
				</v-tab-item>
				<v-tab-item value="staff">
					<StaffSetting></StaffSetting>
				</v-tab-item>
				<v-tab-item value="integration">
					<Integration></Integration>
				</v-tab-item>
				<v-tab-item v-if="false" value="whatsapp">
					<WhatsApp></WhatsApp>
				</v-tab-item>
				<v-tab-item
					v-if="
						AllowAnyOforGetRoleNType([
							'master',
							'admin',
							'manager',
							'super_admin',
							'team_lead',
							'ateam_lead',
						])
					"
					value="google_credentials"
				>
					<GoogleCredentials></GoogleCredentials>
				</v-tab-item> -->
				<!-- <v-tab-item value="leave-category">
					<LeaveCategory></LeaveCategory>
				</v-tab-item> --> */
</script>
